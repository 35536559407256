<template>
  <div class="page" id="auth-register-page">
    <div class="container">
      <div class="page-body">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {},
};
</script>
